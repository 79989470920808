import Header from '../components/Header'
import Dropdown from '../components/Dropdown'
import data from '../data/stakeholderData'
import NodeLinkGraph from '../components/NodeLinkGraph';
import InfoBox from '../components/InfoBox';
import FilterLegend from '../components/FilterLegend';
import {useNetworkContext} from "../NetworkContext";
import React, {useEffect, useState} from "react";

export function StakeholderMap() {

    const [currentHeading, setHeading] = useState("Welcome");

    const {selectedCaseStudy,
        selectedNodes, setSelectedNodes, filteredNodes,
        selectedEdges, setSelectedEdges,
        selectedColoringAttrib, setSelectedColoringAttrib,
        sectorColors} = useNetworkContext();

    const coloringAttrib = "sector"


    useEffect(() => {

        const id = parseInt(selectedCaseStudy)
        const searchData = data.find(entry => entry.id === id);
        if (searchData) {
            setSelectedNodes(searchData.nodes)
            setSelectedEdges(searchData.edges)
            setHeading(searchData.name)
        } else {
            setHeading("Sample Data")
        }
    }, [selectedCaseStudy, setSelectedNodes, setSelectedEdges, setHeading, selectedNodes, selectedEdges, filteredNodes, currentHeading]);


    return (
        <>
            <div className="container-fluid" id="map-container">
                <div className="row mb-4">
                    <div className="col dropdown">
                        <Dropdown/>
                    </div>
                </div>
                <div className="row">
                    {/* Left Sidebar */}
                    <aside className="col-md-3 bg-light p-4 asideBox">
                        <InfoBox/>
                    </aside>

                    <main className="col-md-6 p-4" id="main-map-container">
                        <div className="row mb-4">
                            <h5>{currentHeading}</h5>
                        </div>
                        <div className="row mb-4">
                            <p>This is a simplified Stakeholder Network with Sample Data.</p>
                        </div>
                        <div className="row mb-8">
                            <NodeLinkGraph nodes={selectedNodes} edges={selectedEdges} colors={sectorColors} coloringAttrib={coloringAttrib}/>
                        </div>

                    </main>
                    <aside className="col-md-3 bg-light p-4 asideBox">
                        <FilterLegend/>
                    </aside>
                </div>
            </div>

        </>
    );
}