import React from "react";
import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import { surveyLocalization } from "survey-core";
import { Converter } from "showdown";
import { BACKEND_ENDPOINT } from "../App.js";
import { Modal } from "bootstrap";

import { surveyContent } from "../survey_questions/survey_json.js";

StylesManager.applyTheme("defaultV2");

function onValueChanged(_, options) {
  // TODO: cache data?
  console.log("New value: " + options.value);
}

function onComplete(survey) {
  // TODO: save data as a json/csv? OR pass to python data handler?
  //console.log("Survey completed! Results: " + JSON.stringify(survey.data));
  // TODO: change endpoint to actual server
  fetch(BACKEND_ENDPOINT+'/update_raw_data', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(survey.data)
  })
      .then(response => {
          if (response.ok) {
              openModal();
          }
      })
      .catch(error => {
          console.error('Network error:', error);
      });
}

function openModal() {
    const modal = new Modal(document.getElementById('successModal'))
    modal.show();
}

// TODO: Language choice

export function SurveyPage() {
  // change back to surveyContent
  const model = new Model(surveyContent);
  //model.locale = 'de';
  const converter = new Converter();
  model.onTextMarkdown.add(function (model, options) {
      let str = converter.makeHtml(options.text);
      options.html = str;
  });
  return (
      <>
          <div className="container">
              <Survey
                  model={model}
                  onComplete={onComplete}
                  //onValueChanged={onValueChanged}
              />
          </div>
          <div className="modal fade" id="successModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
              <div className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h1 className="modal-title fs-5" id="exampleModalLabel">Thank you! </h1>
                          <button type="button" className="btn-close" data-bs-dismiss="modal"
                                  aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          .<p>Your survey has been submitted successfully!</p>
                      </div>
                  </div>
              </div>
          </div>
      </>

  );
}

