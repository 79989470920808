import logo from '../img/logo.jpg'
import logoUPV from '../img/Logo_UPV.jpg'
import logoHDA from '../img/Logo_HDA.png'
import React from 'react';

import style from '../style.css'

export default function Header(){
    return(
        <div className="Header">
            <img src={logo} alt="logo"/>
            <img src={logoUPV} alt="logo"/>
            <img src={logoHDA} alt="logo"/>
        </div>
    )
}