import React from "react";
import {useNetworkContext} from '../NetworkContext';

const InfoBox = () => {
    const { selectedNode } = useNetworkContext();
    return (
        <div>
            {selectedNode && (
                <div className="container">
                    <div className="row">
                        <h5>{selectedNode.label}</h5>
                    </div>
                    <div className="row">
                        <div className="col-sm-5"><p><strong>Sector</strong></p></div>
                        <div className="col-sm-5"><p> {selectedNode.sector}</p></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5"><p><strong>Activity</strong></p></div>
                        <div className="col-sm-5"><p>
                            {selectedNode.mainActivity}</p></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5"><p> </p></div>
                        <div className="col-sm-5"><p>{selectedNode.activities}</p></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5"><p><strong>Country, Region</strong></p></div>
                        <div className="col-sm-5"><p>{selectedNode.country}, {selectedNode.region}</p></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5"><p><strong>Number of Employees</strong></p></div>
                        <div className="col-sm-5"><p> {selectedNode.size}</p></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5"><p><strong>Years</strong></p></div>
                        <div className="col-sm-5"><p> {selectedNode.years}</p></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-5"><p><strong>Website</strong></p></div>
                        <div className="col-sm-5"><p> {selectedNode.website}</p></div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default InfoBox;