//import logo from "../logo-farmwise.jpg";

export function HomePage() {
  return (
    <div className="container">
      <div className="col-lg-9 jumbotron" style={{alignSelf: "center"}}>
        <h2>FARMWISE stakeholders survey</h2>
        <p>If you are receiving this survey it is because you are considered an important actor in your region’s water and agri-food system.</p>
        <p>This survey is part of the FARMWISE project (Future Agricultural Resource Management and Water Innovations for a Sustainable Europe), funded by the EU’s Horizon Europe programme. The project aims to enhance resilience in water systems across eight European case studies. For more information, visit <a href="https://www.farmwise-project.eu/" target="_blank">the official website</a>.</p>
        <p>Your responses will help create a “map” (a detailed representation) of the key actors and their interactions in each region where FARMWISE operates.</p>
        <p>Once these maps are developed, they will be shared with all participating organizations. This will allow you to see where your organization is “located” within the agrifood and water ecosystem of your region.</p>
        <p>We believe these maps will strategically benefit both your organization and the broader ecosystem by improving understanding and supporting better decision-making. Our ultimate goal is to enhance water management and strengthen resilience to the impacts of climate change.</p>
        <p>Thank you very much for your participation,</p>
        <div className="signature">The FARMWISE team</div>
      </div>
      <div className="start-survey">
        <button className="start-survey-btn" onClick={event => window.location.href='/survey'}>start survey</button>
      </div>
      <div className="credits">
        <p>ICESI, “Interdisciplinary Center for Digital Transformation - CITRADI” in Colombia</p>
        <p>INGENIO (CSIC-UPV), Universitat Politècnica de València, Spain</p>
        <p>Darmstadt University of Applied Sciences, Germany</p>
      </div>
    </div>
  );
  }
  